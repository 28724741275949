import React, { Component } from "react";
import "./Walkway.css"
class Walkway extends Component {
    constructor(props) {
        super(props);
    }
    render = () => {
        return (
            <div className="walkway" />
        )
    };
};

export default Walkway;