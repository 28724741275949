import React, { Component } from "react";
import "./Walkway.css"
class WalkwayPathHorizontal extends Component {
    constructor(props) {
        super(props);
    }
    render = () => {
        return (
            <div className="walkwayPathHorizontal"></div>
        )
    };
};

export default WalkwayPathHorizontal;